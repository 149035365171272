
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC } from 'react';

const Profile: FC = () => {
  return (
<div className="profile-container">
      <h2>Profile Information</h2>
      <div className="profile-info">
        <label>First Name:</label>
        <span>John</span>
      </div>
      <div className="profile-info">
        <label>Last Name:</label>
        <span>Doe</span>
      </div>
      <div className="profile-info">
        <label>Username:</label>
        <span>johndoe123</span>
      </div>
      
</div>

    
  )
}

export default Profile
