
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

interface Props {
  children: React.ReactNode;
}
const Layout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <main>{props.children}</main>
    </>
  )
}

export default Layout
