import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import { Hub } from 'aws-amplify/utils'

import About from './components/About';
import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';
import Login from './components/Login';
import NavigationBar from './components/NavigationBar';


import './App.scss';
import Research from './components/Research';
import Profile from './components/Profile';


function App() {

  return (
    <>
  <div>
    <Layout>
      <NavigationBar></NavigationBar>
      <div className='content'>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/research' element={<Research />} />
      </Routes>
      </div>
    </Layout>
    
  </div>

    </>
  );
}

export default App;
