
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC } from 'react';
import { fab } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the styles (important)
import { library } from '@fortawesome/fontawesome-svg-core';

import { faGithub } from '@fortawesome/free-brands-svg-icons';

// Initialize FontAwesome library with necessary icons
library.add(fab, faLinkedin, faGithub);

const Contact: FC = () => {
  return (
    <>
      <div>
      <a href="https://www.linkedin.com/company/autogro-ai/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={['fab', 'linkedin']} className="linkedin-icon" />
      </a> __|__
      <a target='_blank' href='https://autogro.gitbook.io/autogro-docs/overview/about-autogro'>
        <FontAwesomeIcon icon={['fab', 'github']} className="linkedin-icon" />
      </a></div>
    </>
  )
}

export default Contact
