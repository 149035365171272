
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC } from 'react';

const Login: FC = () => {
  return (
    <>
      Login now
      <button>Login</button>
    </>
  )
}

export default Login
