
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC } from 'react';

const About: FC = () => {
  return (
    <>
      <div className="container ">
        <div>
          <h4>AutoGro</h4><br></br>
        </div>
      </div>
    </>
  )
}

export default About
