
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { faUser, faPlantWilt } from '@fortawesome/free-solid-svg-icons';

const NavigationBar:FC = () => {

  return (
<nav className='navbar'>
  <ul>
    <li><NavLink to="/">Home</NavLink></li>
    <li><NavLink to="/about">About</NavLink> </li>
    <li><NavLink to="/contact">Contact</NavLink></li>
    <li className='right'><NavLink to="/research"> <FontAwesomeIcon icon={faPlantWilt} className="plantWilt-icon" /></NavLink></li>
    <li className='right'><NavLink to="/profile">
    <FontAwesomeIcon icon={faUser} className="profile-icon" /></NavLink></li>
  </ul>
      
      
      
</nav>
  );

}

export default NavigationBar
