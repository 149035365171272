
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC, useEffect, useState } from 'react';
import logo from '../../assets/leaflogo.png'

const Dashboard: FC = () => {


  const [currentIndex, setCurrentIndex] = useState(1);
  const totalImages = 0;
  const s3BucketUrl = 'https://autogro-device-images.s3.us-east-2.amazonaws.com/inbound/devices/1/';

  useEffect(() => {
    const interval = setInterval(() => {
      rotateImage();
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Run effect only on mount and unmount

  const rotateImage = () => {
    setCurrentIndex((prevIndex: number) => (prevIndex % totalImages) + 1);
  };

  const imageUrl = `${s3BucketUrl}image${currentIndex}.jpg`;

  return (
    <>
    <div>
     {/*<img id="timelapse-image" src={imageUrl} className="deviceImage" /> */}
    </div>
    <div className="App">
        <h1>Autogro AI</h1>
        <img src={logo} className="image-jiggle" alt="logo" />
    </div>


    </>
  );

}

export default Dashboard
