
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FC, useEffect, useState } from 'react';

const getRandomDateWithinLastWeek = () => {
  const now = new Date();
  const lastWeek = new Date(now);
  lastWeek.setDate(now.getDate() - 7);

  const randomDate = new Date(
    lastWeek.getTime() + Math.random() * (now.getTime() - lastWeek.getTime())
  );

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return randomDate.toLocaleDateString();
};


const Research: FC = () => {


    const [userList, setUserList] = useState([
      { id: 1, name: 'John', active: false, lastLogin: getRandomDateWithinLastWeek() },
      { id: 2, name: 'Jane', active: true, lastLogin: getRandomDateWithinLastWeek() },
      { id: 3, name: 'Bob', active: false, lastLogin: getRandomDateWithinLastWeek() },
      // Add more users as needed
    ]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [imageCycleCount, setImageCycleCount] = useState(1);
    const totalImages = 4;
    const s3BucketUrl1 = 'https://autogro-device-images.s3.us-east-2.amazonaws.com/inbound/devices/1/';
    const s3BucketUrl2 = 'https://autogro-device-images.s3.us-east-2.amazonaws.com/inbound/devices/2/';
  
    let counter = 0;
    useEffect(() => {
      const interval = setInterval(() => {
        rotateImage();
        counter++;

        // Check if the desired number of iterations (4 in this case) is reached
        if (counter === 8) {
          clearInterval(interval); // Stop the timer
        }
      }, 1000);
  
      // Clear the interval on component unmount
      return () => clearInterval(interval);
    }, []); // Run effect only on mount and unmount
  
    const rotateImage = () => {
      if (imageCycleCount<=2){
        setCurrentIndex((prevIndex) => (prevIndex % totalImages) + 1);
        setImageCycleCount((prevCount) =>( prevCount + 1));
      }
    };
    const imageUrl1 = `${s3BucketUrl1}image${currentIndex}.jpg`;
    const imageUrl2 = `${s3BucketUrl2}image${currentIndex}.jpg`;
  
    const handleToggle = (userId: any) => {
      setUserList((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, active: !user.active } : user
        )
      );
    };    

    const generateApiKey = () => {
      // This example ensures the key is always 10 characters long
      const apiKey = Math.random().toString(36).substring(2, 12); // Generate a key
      return apiKey.padEnd(10, '0').substring(0, 10); // Pad or truncate to 10 characters
    };
   
  return (
    <>
     <h2>My Devices</h2>
     <div className="devices-container">
      
      <div className="devices-panel">

      <div className="device-panel-data">
        <div>
            <label>DeviceID</label><span>gm448022fe8</span><br />
            <label>Gro Model</label>
            <span>Conway</span>
            <br/>
            <label>Uptime</label>
            <span>5 days, 14 hours, 21 mins</span>
            <br/>
            <label>Crop</label>
            <span>Cherry Tomato</span><br />
          

            <button>Manual Water</button> ||<button>Restart Device</button> || <button>Run Script</button>
            <hr />   <img id="timelapse-image" src={imageUrl1} className="deviceImage" />

        </div>
          <div className="profile-container">
            
            <ul className="user-access-list">
              {userList.map((user) => (
                <li key={user.id} className="user-item">
                  <span>{user.name}</span>
                
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={user.active}
                      onChange={() => handleToggle(user.id)}
                    />
                    <span className="slider"></span>
                  </label>
                  <span>last: {user.lastLogin}</span>
                </li>
              ))}
              <li>Send an Invite: <input></input></li>
            </ul>
          </div>
        </div>  
      </div>
      <div className="devices-panel">

<div className="device-panel-data">
  <div>
      <label>DeviceID</label><span>gm48449de8</span><br />
      <label>Gro Model</label>
      <span>Conway</span>
      <br/>
      <label>Uptime</label>
      <span>21 days, 2 hours, 3 mins</span>
      <br/>
      <label>Crop</label>
      <span>Dill</span><br />
    

      <button>Manual Water</button> ||<button>Restart Device</button> || <button>Run Script</button>
      <hr />   <img id="timelapse-image" src={imageUrl2} className="deviceImage" />

  </div>
    <div className="profile-container">
      
      <ul className="user-access-list">
        {userList.map((user) => (
          <li key={user.id} className="user-item">
            <span>{user.name}</span>
          
            <label className="switch">
              <input
                type="checkbox"
                checked={user.active}
                onChange={() => handleToggle(user.id)}
              />
              <span className="slider"></span>
            </label>
            <span>last: {user.lastLogin}</span>
          </li>
        ))}
        <li>Send an Invite: <input></input></li>
      </ul>
    </div>
  </div>  
</div>
    </div>
    <div className="devices-add">
      <h2>Register new device</h2>
      <b>SCAN QR CODE</b> <span>  and provide code "GROWSTAR"</span>
     <hr />
     <h2>OR</h2>
     <hr />
      <form action="" method="post">
        <label htmlFor="deviceID"> <b>ENTER</b> </label><input type="input" name='deviceID' />
        <input type="submit" value="Register" /> (provide deviceId)
      </form>
  </div>  

    </>
  )
}

export default Research
